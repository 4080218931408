<template>
    <div>
        <section class="banner-section section-t-space header-margin">
            <div class="d-flex justify-content-center mb-3" v-if="loading">
                <BannerLoader />
            </div>

            <div v-if="!loading" class="custom-container">
                <div class="swiper banner1">
                    <div class="swiper-wrapper">
                        <Carousel :autoplay="2000" :wrap-around="true">
                            <Slide v-for="slide in banner" :key="slide.id">
                                <div class="swiper-slide">
                                    <!-- <router-link :to="{name: 'Promotion', params: {id: slide.id, token: this.$route.params.token}}"> -->
                                        <img class="img-fluid banner-img" :src="getImageUrl(slide.image)" alt="banner1" />
                                    <!-- </router-link> -->
                                </div>
                            </Slide>

                            <template #addons>
                                <Pagination />
                            </template>
                        </Carousel>

                    <div class="swiper-pagination"></div>

                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { Carousel, Pagination, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import BannerLoader from '@/components/loader/BannerLoader.vue';

export default ({
  name: 'HeaderSlider',
  components: {
    Carousel,
    Slide,
    Pagination,
    BannerLoader
  },
  data(){
    return {
        banner: [
        {
            id: 1,
            promotion_name: "Diskon 20% BNI",
            description: "Kamu bisa dapetin diskon 20% dari Tamani Cafe dengan QRIS BNI Mobile Banking setiap hari Sabtu dan Minggu, promo berlaku hingga 31 Desember 2022.",
            slug: "diskon_20%_bni",
            image: "banner1.jpg",
            banner_type: "header",
            active: 1,
            start_date: "2023-05-01",
            end_date: "2023-12-31",
            restaurant_id: 1,
            created_at: "2023-05-24T04:56:54.000000Z",
            updated_at: "2023-05-24T04:56:54.000000Z"
        },
        {
            id: 2,
            promotion_name: "Tamani x Skinny Scoop",
            description: "",
            slug: "diskon_20%_bni",
            image: "banner2.jpg",
            banner_type: "header",
            active: 1,
            start_date: "2023-05-01",
            end_date: "2023-12-31",
            restaurant_id: 1,
            created_at: "2023-05-24T04:56:54.000000Z",
            updated_at: "2023-05-24T04:56:54.000000Z"
        },
        {
            id: 3,
            promotion_name: "FREE Thai Iced Tea or ES Kopi Susu",
            description: "FREE Thai Iced Tea or ES Kopi Susu for minimum purchase Rp. 150,000.",
            slug: "free_thai_iced_tea_or_kopi_susu",
            image: "banner3.jpg",
            banner_type: "header",
            active: 1,
            start_date: "2023-05-01",
            end_date: "2023-12-31",
            restaurant_id: 1,
            created_at: "2023-05-24T04:56:54.000000Z",
            updated_at: "2023-05-24T04:56:54.000000Z"
        },
        {
            id: 4,
            promotion_name: "Promo Kuliner Heboh Diskon 20% BRI",
            description: "Diskon 20% menggunakan Kartu Debit BRI  & Kartu Kredit BRI Mastercard/ BRImo. Khusus Tamani Kafe berlaku minimal transaksi Rp 250 ribu maksimal diskon Rp 100 ribu, khusus Tamani Express dan Tamani Plus berlaku minimal transaksi Rp 50 ribu maksimal diskon Rp 50 ribu.",
            slug: "promo_kuliner_heboh_diskon_20%_bri",
            image: "banner4.jpg",
            banner_type: "header",
            active: 1,
            start_date: "2023-05-01",
            end_date: "2023-12-31",
            restaurant_id: 1,
            created_at: "2023-05-24T04:56:54.000000Z",
            updated_at: "2023-05-24T04:56:54.000000Z"
        },
        {
            id: 5,
            promotion_name: "Tamani 20% OFF + Free Delivery",
            description: "Free Delivery berlaku untuk jarak maksimal 3 KM. Diskon 20% untuk semua menu. Minimum pembelian Rp. 150.000 (sebelum tax). Hanya menerima pembayaran Cash. Waktu pesan antar adalah Senin - Minggu (11:30 - 19:30).",
            slug: "tamani_20%_off_&_free_delivery",
            image: "banner5.jpg",
            banner_type: "header",
            active: 1,
            start_date: "2023-05-01",
            end_date: "2023-12-31",
            restaurant_id: 1,
            created_at: "2023-05-24T04:56:54.000000Z",
            updated_at: "2023-05-24T04:56:54.000000Z"
        }
        ],
        loading: false,
    }
  },
  mounted() {
    this.getHeaderSlider()
  },
  methods: {
    async getHeaderSlider(){
        this.loading = true;
        setTimeout(() => {
            this.loading = false;
        }, 1000);
    },
    getImageUrl(filename){
        return '/assets/images/banner/' + filename;
    }
  }
})
</script>

<style>
.header-margin{
    margin-top: -20px
}

.header-wrapper {
  direction: ltr;
}

.banner-img{
    width: 90%;
    height: auto;
}
</style>